.Garage {
  animation-name: TransiotionY;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-duration: 1s;
  transform: translateY(100%);
}

.Window {
  animation-name: TransiotionYMinus;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  transform: translateY(-100%);
}

.Door {
  animation-name: TransiotionX;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  animation-duration: 1s;
  transform: translateX(100%);
}

.heroImg {
  position: absolute;
  right: 15%;
  top: 20%;
  width: 50%;
}

@keyframes TransiotionY {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes TransiotionYMinus {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes TransiotionX {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.cls1 {
  fill: #eee9ea;
}

.cls2 {
  fill: #c6c3c4;
}

.cls3 {
  fill: #bcbabb;
}

.cls4 {
  fill: #b1b0b0;
}

.cls5 {
  fill: #1a1a1a;
}

.cls6 {
  fill: url(#Unbenannter_Verlauf_14);
}

.cls7 {
  fill: url(#Unbenannter_Verlauf_8);
}

.cls8 {
  fill: #5f5f5f;
}

.cls9 {
  fill: url(#Unbenannter_Verlauf_14);
}

.cls10 {
  fill: url(#Unbenannter_Verlauf_12);
}

.cls11 {
  fill: url(#Unbenannter_Verlauf_14);
}

.cls12 {
  fill: url(#Unbenannter_Verlauf_12);
}

.cls13 {
  fill: url(#Unbenannter_Verlauf_14);
}

.cls14 {
  fill: url(#Unbenannter_Verlauf_14);
}

.cls15 {
  opacity: 0.8;
}

.cls16 {
  fill: url(#Unbenannter_Verlauf_15);
}

.cls17 {
  fill: gray;
}

.cls18 {
  fill: #b3b3b3;
}

.cls19 {
  fill: #823c01;
}

.cls20 {
  fill: #712901;
}

.cls21 {
  fill: #450000;
}

.cls22 {
  fill: #914901;
}

.cls23 {
  fill: #b25910;
}

.cls24,
.cls27,
.cls28 {
  fill: #29abe2;
}

.cls25 {
  fill: #fff;
  opacity: 0.17;
}

.cls25,
.cls27,
.cls28,
.cls32,
.cls33,
.cls35,
.cls37,
.cls39,
.cls41,
.cls43,
.cls45,
.cls47,
.cls49,
.cls51,
.cls53,
.cls55 {
  isolation: isolate;
}

.cls26 {
  fill: #666;
}

.cls27 {
  opacity: 0.69;
}

.cls28 {
  opacity: 0.5;
}

.cls29 {
  fill: #5b1414;
}

.cls30,
.cls57 {
  fill: none;
  strokemiterlimit: 10;
}

.cls30 {
  stroke: #7c4216;
  stroke-width: 3px;
}

.cls31 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls32,
.cls35,
.cls37,
.cls39,
.cls41,
.cls43,
.cls45,
.cls47,
.cls49,
.cls51,
.cls53,
.cls55 {
  opacity: 0.3;
}

.cls32 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls33 {
  fill: #995b00;
  opacity: 0.9;
}

.cls34 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls35 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls36 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls37 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls38 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls39 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls40 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls41 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls42 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls43 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls44 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls45 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls46 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls47 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls48 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls49 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls50 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls51 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls52 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls53 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls54 {
  fill: url(#Unbenannter_Verlauf_23);
}

.cls55 {
  fill: url(#Unbenannter_Verlauf_24);
}

.cls56 {
  fill: #89491d;
}

.cls57 {
  stroke: #89491d;
  stroke-width: 6px;
}
