.container {
  width: 100%;
  text-align: center;
  font-family: "Anton", sans-serif;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  color: white;
  padding: 10px;
  z-index: 1;
  font-size: 1em;
  position: absolute;
  width: 100%;
  bottom: 0px;
  box-shadow: 0 -3px 5px 2px rgba(0, 190, 255, 0.3);
}

.btntext span {
  font-family: "Anton", sans-serif;
}
