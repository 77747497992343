* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  background-color: rgb(250, 250, 250);
  position: relative;
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 65px;
  padding-bottom: 80px;
}
