.container {
  width: 90%;
  margin: 20px 0;
  white-space: pre-wrap;
}

.containerItems {
  width: 100%;
}
.items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card {
  margin: 1% 2% !important;
}

.paper {
  padding: 10px;
  text-align: justify;
}

.sizes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 770px) {
  .card {
    margin: 2% 5% !important;
  }
  .sizes {
    flex-direction: column;
    min-height: 300px;
  }
}
