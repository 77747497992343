.container {
	width: 90%;
	overflow: hidden;
	margin: 20px 0;
}
.items {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
