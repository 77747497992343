.container {
	width: 90%;
	white-space: pre-wrap;
}
.items {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.paper {
	padding: 10px;
	text-align: center;
}

.button {
	background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)';
	border: 0;
	border-radius: 3;
	box-shadow: '0 3px 5px 2px rgba(255, 105, 135, .3)';
	color: 'white';
	height: 48;
	padding: '0 30px';
}
