.hero {
  height: 100vh;
  width: 100%;
  position: relative;
}

.products {
  position: absolute;
  right: 10%;
  bottom: 17%;
  z-index: 19;
  opacity: 1;
  width: 780px;
}

.herotext {
  position: absolute;
  font-family: "Anton", sans-serif;
  top: 50%;
  width: 30%;
  left: 30%;
  font-size: 3em;
  z-index: 100;
  animation-name: leftright;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  opacity: 1;
  transform: translate(-50%, -50%);
}

@keyframes leftright {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 30%;
    opacity: 1;
  }
}

@media (max-width: 770px) {
  .herotext {
    text-shadow: 0 0 10px #fff; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 10px #fff;
    -webkit-text-shadow: 0 0 10px #fff;
    width: 50%;
    left: 30%;
    font-size: 3em;
  }

  .products {
    right: 5%;
    opacity: 1;
    width: 720px;
  }

  @keyframes leftright {
    from {
      opacity: 0;
      left: 0%;
    }
    to {
      left: 30%;
      opacity: 1;
    }
  }
}

@media (max-width: 420px) {
  .herotext {
    text-shadow: 0 0 10px #fff; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 10px #fff;
    -webkit-text-shadow: 0 0 10px #fff;
    width: 90%;
    left: 50%;
    font-size: 2em;
  }
  .products {
    right: 5%;

    bottom: 29%;
    opacity: 1;
    width: 320px;
  }

  @keyframes leftright {
    from {
      opacity: 0;
      left: 0%;
    }
    to {
      left: 50%;
      opacity: 1;
    }
  }
}
