.itemimg {
	border-radius: 100%;
	width: 260px;
}

.container {
	padding: 20px 0;
}

.paper {
	padding: 10px;
	text-align: center;
}
